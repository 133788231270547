import React, { useEffect } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
// import IndustryCasestudyTabs from "../../molecules/Industry/IndustryCasestudyTabs";
// import SlantedHeader from "../../molecules/aboutus/AboutUsHeader";
// import IndustrySlider from "../../molecules/Industry/IndustrySlider";
// import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import bgImgIn from "../../images/industryassets/manufacturing/ManufacturingIn.png";
import bgImgEn from "../../images/industryassets/manufacturing/ManufacturingEn.png";
import bgImgJa from "../../images/industryassets/manufacturing/ManufacturingJa.png";
import Banner1 from "../../images/industryassets/manufacturing/manufacturing_eng.png";
import Banner4 from "../../images/industryassets/manufacturing/business_lang.png";
import Banner2 from "../../images/industryassets/manufacturing/training.png";
import Banner3 from "../../images/industryassets/manufacturing/eng_technology.png";
import { useStaticQuery, graphql } from "gatsby";
import IndustryOurClients from "../../molecules/Industry/IndustryOurClients";
import mobileImg from "../../images/industryassets/banners/ManufacturingMobileImg.png";
import tabletImg from "../../images/industryassets/banners/ManufacturingTabletImg.png";

import workflowImg1 from "../../images/industryassets/manufacturing/workflow1.png";
import workflowImg2 from "../../images/industryassets/manufacturing/workflow2.png";
import workflowImg3 from "../../images/industryassets/manufacturing/workflow3.png";

import EngTechImage1 from "../../images/industryassets/manufacturing/eng_tech_op.png";
import EngTechImage2 from "../../images/industryassets/manufacturing/eng_tech_ar.png";
import EngTechImage3 from "../../images/industryassets/manufacturing/eng_tech_auto.png";
import capabilityImage1 from "../../images/industryassets/manufacturing/jig_design.png";
import capabilityImage2 from "../../images/industryassets/manufacturing/stamping.png";
import capabilityImage3 from "../../images/industryassets/manufacturing/digital_man.png";
import capabilityImage4 from "../../images/industryassets/manufacturing/digital_work.png";
import capabilityImage5 from "../../images/industryassets/manufacturing/print_3d.png";

import conventional1 from "../../images/industryassets/manufacturing/plant_layout.png";
import conventional3 from "../../images/industryassets/manufacturing/plc_programming.png";
import conventional4 from "../../images/industryassets/manufacturing/cnc_programming.png";
import conventional2 from "../../images/industryassets/manufacturing/process_eng.png";
import conventional5 from "../../images/industryassets/manufacturing/quality_eng.png";

import busLangService1 from "../../images/industryassets/manufacturing/business_cart.png";
import busLangService2 from "../../images/industryassets/manufacturing/business_lang_.png";
import bgImg from "../../images/industryassets/manufacturing.png";
import IndustryNewHeader from "../../molecules/Industry/v2.1/IndustryNewHeader";
import IndustryScrollerComponent from "../../molecules/Industry/v2.1/IndustryScrollerComponent";
import { useState } from "react";
// import IndustryOurClients from "../../molecules/Industry/IndustryOurClients";

import IndustryFullImageHeader from "../../molecules/Industry/v2.1/IndustryFullImageHeader";

const Manufacturing = () => {
  const [menuBarValue, setmenuBarValue] = useState(0);

  const data = useStaticQuery(graphql`
    query GetManufacturingData {
      gcms {
        industryPages(where: { industrPageType: MANUFACTURING }) {
          industrPageType
          headerParagraph
          solutionsPageSlides {
            listItems
            slideHeading
            paragraph
            slideImage {
              url
            }
            url
          }
          insightPages {
            image {
              url
            }
            slug
            title
            typeOfInsight
            writtenBy
            shortDescription
          }
          linkedInArticlesIframeUrl
        }
      }
    }
  `);

  useEffect(() => {
    try {
      let menuBar = document.querySelector("#scrollspy-menu-bar");
      let top = menuBar.getBoundingClientRect().top;
      let bottom = menuBar.getBoundingClientRect().bottom;
      setmenuBarValue(top);
      console.log(top);
    } catch (error) {}
  }, []);
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        pageName={"Manufacturing Industry"}
        canonicalLink={"https://www.goken-global.com/industry/manufacturing/"}
        title={"Manufacturing Industry: Manufacturing Innovations by Goken"}
        description={
          "Goken Global offers tailored solutions for the manufacturing industry to enhance productivity and innovation."
        }
        // meta="Manufacturing, manufacturing services, manufacturing engineering, automotive manufacturing, OEMs, OEM, lean manufacturing, manufacture, smart factory"
        facebookTitle={
          "Goken | Manufacturing Automation, Training Solutions in USA, India & Japan"
        }
        facebookDescription={
          "manufacturing automation, manufacturing solutions"
        }
        keywords={"manufacturing automation, manufacturing solutions"}
      ></SEO>
      <div id="manufacturing-page-top"></div>
      {data.gcms && (
        <>
          {/* <IndustryNewHeader
            header="Manufacturing"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get In Touch"
            btnLink="/contactus/"
            background="manufacturing"
            isColorPrimary={true}
            bgImageClass="bg-image-manufacturing"
            bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgTablet={tabletImg}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            currentPage="industry aerospace"
            bgImgMobile={mobileImg}
          /> */}
          <IndustryFullImageHeader
            bgImgTablet={tabletImg}
            header="Manufacturing"
            para={data.gcms.industryPages[0].headerParagraph}
          />
          <IndustryOurClients isManufacturing={true} />

          {/* <SlantedHeader
            header="Manufacturing"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get In Touch"
            btnLink="/contactus/"
            background="manufacturing"
            isColorPrimary={true}
            bgImageClass="bg-image-manufacturing"
            bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgTablet={tabletImg}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            currentPage="industry aerospace"
            bgImgMobile={mobileImg}
            children={
              <IndustryOurClients isManufacturing={true}></IndustryOurClients>
            }
          ></SlantedHeader> */}
          {/* <IndustryOurClients isManufacturing={true}></IndustryOurClients> */}
          <IndustryScrollerComponent
            images={[Banner1, Banner2, Banner3, Banner4]}
            topLeftValue={menuBarValue}
            capabilityImages={[
              capabilityImage1,
              capabilityImage2,
              capabilityImage3,
              capabilityImage4,
              capabilityImage5,
            ]}
            conventionalImages={[
              conventional1,
              conventional2,
              conventional3,
              conventional4,
              conventional5,
            ]}
            trainingWorkflows={[workflowImg1, workflowImg2, workflowImg3]}
            trainingWorkflowsAlts={[
              "Workflow Process Diagram 1",
              "Workflow Process Diagram 2",
              "Workflow Process Diagram 3",
            ]}
            engTechImages={[EngTechImage1, EngTechImage2, EngTechImage3]}
            engTechImagesAlts={[
              "Engineering Technology Automotive Graphic",
              "Engineering Technology AR Graphic",
              "Engineering Technology Automotive Graphic",
            ]}
            businessLangImages={[busLangService1, busLangService2]}
            businessLangImagesAlts={[
              "Business Cart Graphic",
              "Business Language Graphic",
            ]}
          />
          {/* <IndustrySlider
            SliderItems={data.gcms.industryPages[0].solutionsPageSlides}
            heading="Manufacturing"
          ></IndustrySlider> */}

          {/* <IndustryCasestudyTabs
            heading={"OUR WORK IN THE MANUFACTURING INDUSTRY"}
            insights={data.gcms.industryPages[0].insightPages}
            linkedinArticlesUrl={
              data.gcms.industryPages[0].linkedInArticlesIframeUrl
            }
          ></IndustryCasestudyTabs> */}
        </>
      )}
      {/* <ContactForm showForm={true} typeOfForm="General Inquiry"></ContactForm> */}
      <ImageBanner
        heading="Learn more about our services in the Manufacturing industry"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default Manufacturing;
